import { useMemo } from 'react'

import { useAuth as useClerkAuth } from '@clerk/clerk-react'
import { GetAppStateQuery, GetAppStateQueryVariables } from 'types/graphql'

import { QueryKey, useQuery } from 'src/utils/queryClient'

const GetAppState = gql`
  query GetAppStateQuery {
    user: me {
      ...UserDataFragment
      recordTypeAccesses {
        id
        recordType {
          ...RecordTypeFragment
        }
      }
    }
    organization: myOrganization {
      ...OrganizationFragmentWithUsers
      inspectionResults {
        ...InspectionResultFragment
      }
      reportTemplateDocumentOptions {
        documentOptions {
          displayName
          documentType
          includeByDefault
        }
        reportTemplateType
      }
    }
    company: myCompany {
      ...CompanyFragment
    }
  }
`

export const useAppStateQuery = () => {
  const { userId } = useClerkAuth()
  const queryResult = useQuery<GetAppStateQuery, GetAppStateQueryVariables>({
    queryKey: [QueryKey.AppState, userId],
    queryDocument: GetAppState,
    variables: {},
    enabled: !!userId,
  })
  const data = useMemo(
    () => ({
      company: queryResult.data?.company,
      organization: queryResult.data?.organization,
      user: queryResult.data?.user,
    }),
    [queryResult.data]
  )
  return {
    ...queryResult,
    data,
  }
}
